.global-filter .ant-select-selector {
  padding: 0 !important;
}

.option-wrapper {
  display: flex;
  text-decoration: none !important;
  white-space: normal;
  word-wrap: break-word;
  max-width: 100%;
  cursor: default;
}

.rc-virtual-list .ant-select-item {
  padding: 5px;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #ebebeb;
}

.ant-select-item-option-content {
  border-radius: 6px;
}

.option-link {
  display: flex;
  color: #00B27B !important;
  white-space: nowrap;
  align-items: center;
  gap: 4px;
}

.global-filter-input {
  width: 360px !important;
  max-width: 260px;
  height: 42px;
  border-radius: 8px !important;
}

.option-card {
  padding: 2px;
  border-radius: 6px;
  width: 100%;
}

.option-card .ant-card-body {
  padding: 8px;
}

.ant-card-meta {
  margin: 0% !important;
}

.option-author {
  display: flex;
  align-items: center;
  text-wrap: auto;
  font-size: 10px;
}

.option-title-wrap {
  display: flex;
  white-space: pre-wrap;
  font-size: 10px;
  font-weight: 600;
}

.option-text-wrap {
  display: flex;
  flex-direction: column;
}

.option-series-wrap {
  display: flex;
  white-space: pre-wrap;
  font-size: 10px;
}

.option-img {
  width: 50px;
  height: 70px;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tab-tags-container {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 8px;
  gap: 4px;
}

.tab-links {
  display: flex;
  flex-flow: wrap;
  justify-content: end;
  font-size: 12px;
  font-weight: 500;
  align-items: center;
  gap: 4px;
}